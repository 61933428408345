<template>
  <div class="card" v-if="identity.entityId === null">
    <div class="flex justify-content-center" v-if="loadingDatabase">
      <ProgressSpinner class="spinner"/>
    </div>
    <div v-if="!loadingDatabase">
      <h5>Base de Datos</h5>
      <div class="titles">CONEXIONES</div>
      <DataTable :value="dbStats && dbStats.connections"
                 responsiveLayout="scroll"
                 :headerStyle="legendHeaderStyle"
                 class="p-datatable-sm">
        <Column field="datname" header="Base de Datos" :sortable="true" :headerStyle="legendHeaderStyle" bodyStyle="text-align: left"/>
        <Column field="state" header="Estado" :sortable="true" :headerStyle="legendHeaderStyle" bodyStyle="text-align: center">
          <template #body="{data}">
            <Badge class="status-badge"
                   :value="getDBStatus(data.state)"
                   :severity="getDBSeverity(data.state)"
                   v-if="data.state"
            >

            </Badge>
          </template>
        </Column>

        <Column field="connections" header="Numero de conexiones" :sortable="true" :headerStyle="legendHeaderStyle" bodyStyle="text-align: center"/>
      </DataTable>
<Divider/>
      <div class="titles">TABLAS MAS GRANDES</div>
      <div class="card">
        <h5>Horizontal</h5>
        <Chart type="bar" :data="dbStats && dbStats.largestTables" :options="horizontalOptions" />
      </div>

<!--      <DataTable :value="dbStats && dbStats.largestTables"-->
<!--                 responsiveLayout="scroll"-->
<!--                 :headerStyle="legendHeaderStyle"-->
<!--                 class="p-datatable-sm">-->
<!--        <Column field="table_name" header="Tabla" :sortable="true" :headerStyle="legendHeaderStyle" bodyStyle="text-align: left"/>-->
<!--        <Column field="space_used" header="Espacio utilizado" :sortable="true" :headerStyle="legendHeaderStyle" bodyStyle="text-align: center"/>-->
<!--      </DataTable>-->
    </div>
  </div>
</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import StatsService from "@/service/backend/StatsService";

export default {
  name: "Database",
  mixins: [generalMixin],
  created() {
    this.statsService = new StatsService;
    this.identity = this.getIdentity();
  },
  data() {
    return {
      identity: null,
      loadingDatabase: false,
      dbStats: null,
      legendHeaderStyle: 'background-color: var(--surface-a); color: var(--surface-900)',

      horizontalOptions: {
        indexAxis: 'y',
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      },
    }
  },
  async mounted() {
    this.loadingDatabase = true;
    this.dbStats = await this.statsService.getDbStats()
    this.loadingDatabase = false;
  },
  methods: {
    getDBStatus(status) {
      const statusArr = {
        'idle': 'INACTIVO',
        'active': 'ACTIVO',
      };
      return  statusArr[status];
    },
    getDBSeverity(status) {
      return (status === 'active') ? 'success' : 'danger';
    },
  },

}
</script>

<style scoped>
.titles {
  color: var(--green-banklot);
  font-weight: bold;
  padding-bottom: 5px;
  font-size: small;
}
</style>